<script lang="ts" setup>
import { getInjectiveAddress } from '@injectivelabs/sdk-ts'
import { StatusType } from '@injectivelabs/utils'
import { TokenType } from '@injectivelabs/token-metadata'
import { GlobalEvents, GlobalSearchType } from '@/types/enum'
import { isNativeFormat, isSubaccountFormat } from '@/app/utils/helper'
import { denomClientSync } from '@/app/Services'

const router = useRouter()
const contractStore = useContractStore()
const transactionStore = useTransactionStore()

const props = defineProps({
  lg: Boolean,

  placeholder: {
    type: String,
    required: true
  }
})

const { emit: eventBusEmit } = useEventBus<StatusType>(GlobalEvents.Status)

const search = ref('')

const token = computed(() => denomClientSync.getDenomToken(search.value))

const isCw20Token = computed(
  () =>
    token.value &&
    token.value.tokenType === TokenType.Cw20 &&
    token.value.cw20?.address &&
    token.value.cw20?.address.startsWith('inj')
)

const type = computed(() => {
  const searchTrimmed = search.value.trim()

  if (searchTrimmed === '') {
    return ''
  }

  if (
    (searchTrimmed.toLowerCase().startsWith('inj') &&
      searchTrimmed.toLowerCase() !== 'inj') ||
    isCw20Token.value
  ) {
    return GlobalSearchType.Account
  }

  if (isSubaccountFormat(searchTrimmed)) {
    return GlobalSearchType.Subaccount
  }

  if (
    searchTrimmed.toLowerCase().startsWith('0x') &&
    searchTrimmed.length === 42
  ) {
    return GlobalSearchType.EtherAccount
  }

  if (
    searchTrimmed.toLowerCase().startsWith('peggy') &&
    searchTrimmed.length === 47
  ) {
    return GlobalSearchType.BridgedERC20
  }

  if (
    searchTrimmed.toLowerCase().startsWith('ibc') &&
    searchTrimmed.length === 68
  ) {
    return GlobalSearchType.Ibc
  }

  if (isNativeFormat(searchTrimmed)) {
    return GlobalSearchType.Native
  }

  if (
    searchTrimmed.toLowerCase().startsWith('0x') ||
    (!searchTrimmed.toLowerCase().startsWith('0x') &&
      searchTrimmed.length === 64)
  ) {
    return GlobalSearchType.Transaction
  }

  if (!Number.isNaN(Number(searchTrimmed) && Number(searchTrimmed) > 0)) {
    return GlobalSearchType.Block
  }

  return ''
})

const rules = computed(() => {
  if (type.value === GlobalSearchType.Account) {
    return GlobalSearchType.Account
  }

  if (type.value === GlobalSearchType.Block) {
    return GlobalSearchType.Block
  }

  if (type.value === GlobalSearchType.BridgedERC20) {
    return GlobalSearchType.BridgedERC20
  }

  if (type.value === GlobalSearchType.EtherAccount) {
    return GlobalSearchType.EtherAccount
  }

  if (type.value === GlobalSearchType.Ibc) {
    return GlobalSearchType.Ibc
  }

  if (type.value === GlobalSearchType.Native) {
    return GlobalSearchType.Native
  }

  if (type.value === GlobalSearchType.Transaction) {
    return GlobalSearchType.Transaction
  }

  return 'validGlobalSearchFormat'
})

const wrapperClasses = computed(() => {
  if (!props.lg) {
    return 'border border-gray-500 rounded-lg py-3 px-4'
  }

  return 'bg-white text-black rounded-lg py-2 md:py-3 lg:py-4 px-4 input-shadow'
})

const { errors: searchErrors, setValue: setSearchValue } = useStringField({
  name: 'search',
  dynamicRule: rules,
  rule: ''
})

function handleSearchEvent() {
  const searchTrimmed = search.value.trim()

  if (searchTrimmed === '' || searchErrors.value.length > 0) {
    return
  }

  if (type.value === GlobalSearchType.Account) {
    eventBusEmit(StatusType.Loading)

    const cw20Address =
      isCw20Token.value && token.value
        ? token.value.cw20?.address || ''
        : searchTrimmed

    contractStore
      .fetchContract(cw20Address)
      .then(() => {
        router.push({
          name: 'contract-contract',
          params: {
            contract: cw20Address
          }
        })
      })
      .catch(() => {
        router.push({
          name: 'account-account',
          params: { account: searchTrimmed }
        })
      })
      .finally(() => {
        setTimeout(() => {
          eventBusEmit(StatusType.Idle)
        }, 100)
      })
  }

  if (type.value === GlobalSearchType.Subaccount) {
    const ethAddress = searchTrimmed.slice(0, 42)
    const account = getInjectiveAddress(ethAddress)

    router.push({
      name: 'account-account',
      params: { account }
    })
  }

  if (type.value === GlobalSearchType.EtherAccount) {
    router.push({
      name: 'account-account',
      params: { account: getInjectiveAddress(searchTrimmed) }
    })
  }

  if (type.value === GlobalSearchType.Block) {
    router.push({
      name: 'block-block',
      params: { block: searchTrimmed }
    })
  }

  if (
    type.value === GlobalSearchType.Native ||
    type.value === GlobalSearchType.Ibc ||
    type.value === GlobalSearchType.BridgedERC20
  ) {
    const tokenType =
      type.value === GlobalSearchType.Native
        ? TokenType.Native
        : type.value === GlobalSearchType.Ibc
        ? TokenType.Ibc
        : TokenType.Erc20

    router.push(`/asset/?denom=${searchTrimmed}&tokenType=${tokenType}`)
  }

  if (type.value === GlobalSearchType.Transaction) {
    eventBusEmit(StatusType.Loading)

    transactionStore
      .fetchTransaction(searchTrimmed)
      .then(() => {
        router.push({
          name: 'transaction-transaction',
          params: { transaction: searchTrimmed }
        })
      })
      .catch(() => {
        router.push({
          name: 'transaction-transaction',
          params: { transaction: `0x${searchTrimmed.toLowerCase()}` }
        })
      })
      .finally(() => {
        setTimeout(() => {
          eventBusEmit(StatusType.Idle)
        }, 100)
      })
  }
}

function syncSearchValidation(value: string) {
  setSearchValue(value)
}
</script>

<template>
  <div class="relative">
    <AppInput
      v-model="search"
      v-bind="{
        lg,
        placeholder,
        wrapperClasses,
        showSearch: true,
        border: !lg,
        disabled: searchErrors.length > 0
      }"
      @enter="handleSearchEvent"
      @update:modelValue="syncSearchValidation"
    />
    <div
      v-if="searchErrors.length > 0"
      :class="{ 'absolute w-full': !lg }"
      class="mt-3 rounded-lg bg-red-100 px-4 py-2.5"
    >
      <p class="tracking-1 text-sm text-white">
        {{ searchErrors[0] }}
      </p>
    </div>
  </div>
</template>
